<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<div class="tableModule" v-show="ListType">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增角色</el-button>
					<!-- <el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数" @change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select> -->
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="table"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="角色名称" show-overflow-tooltip></el-table-column>
							<el-table-column min-width="200%" prop="menus" label="菜单权限" show-overflow-tooltip>
								<template slot-scope="scope">
									<div :title="scope.row.allMenus">{{ scope.row.menus }}</div>
								</template>
							</el-table-column>

							<!-- <el-table-column min-width="200%" prop="menus" label="菜单权限" show-overflow-tooltip></el-table-column> -->
							<el-table-column prop="updateUser" label="修改人" show-overflow-tooltip></el-table-column>
							<el-table-column prop="updateTime" label="修改时间" show-overflow-tooltip></el-table-column>
							<el-table-column fixed="right" label="操作" width="260">
								<template slot-scope="scope">
									<el-button type="text" @click="update(scope.row, 0)">查看</el-button>
									<el-button type="text" @click="update(scope.row, 1)">编辑权限</el-button>
									<el-button type="text" @click="remove(scope.row)" v-if="scope.row.canDelete == 1">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
		<div class="tableModule" v-show="!ListType">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>角色权限</span>
				</div>
				<div class="title-right" @click="close()"><i class="el-icon-close fangda"></i></div>
			</div>
			<el-form ref="form" :model="form" :rules="rules">
				<el-form-item prop="name" label="角色名称："><el-input v-model="form.name" :disabled="look == 0"></el-input></el-form-item>
				<el-form-item v-if="look == 0" label="菜单权限：">
					<el-input type="textarea" style="width: 1920px;font-size: 12px;" :disabled="true" v-model="form.desc"></el-input>
				</el-form-item>
			</el-form>
			<div style="display: flex;flex-direction: row;">
				<span class="spanssss">角色权限：</span>
				<div class="treeBox">
					<el-tree
						class="el-tree"
						ref="tree"
						:data="permData"
						show-checkbox
						node-key="id"
						:default-checked-keys="checkdKey"
						:props="defaultProps"
						:default-expanded-keys="defaultShowNodes"
						:render-content="renderContent"
						@node-expand="handleExpand"
						@check="checkFn"
						:check-on-click-node="true"
					></el-tree>
				</div>
			</div>
			<div class="footer" v-if='look!=0'>
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			showEditDialog: false,
			ListType: true,
			permData: [],
			checkdKey: [],
			defaultShowNodes: [],
			defaultProps: {
				children: 'child',
				label: 'name'
			},
			form: {
				id: '',
				name: '',
				desc: ''
			},
			look: 0,
			rules: {
				name: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
			}
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			this.$get('/user-api/ent/role/list', this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		//打开添加弹窗
		insert() {
			this.look = 1;
			this.getTree();
		},
		//打开编辑弹窗
		update(row, type) {
			this.look = type;
			this.getTree();
			this.$get('/user-api/ent/role/get', {
				id: row.id
			}).then(res => {
				if (res.code == 1000) {
					this.form.id = res.data.id;
					this.form.name = res.data.name;
					let str = '';
					res.data.allMenus.forEach(item => {
						str += item + ',';
					});
					this.form.desc = str;
					this.checkdKey = res.data.menuIds;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		getDefaultShowNodes(num, children) {
			--num;
			if (num >= 0) {
				for (var i = 0; i < children.length; i++) {
					this.defaultShowNodes.push(children[i].id);
					if (children[i].child) {
						this.getDefaultShowNodes(num, children[i].child);
					}
				}
			}
		},
		disabledFn(children) {
			for (var i = 0; i < children.length; i++) {
				children[i].disabled = true;
				if (children[i].child) {
					this.disabledFn(children[i].child);
				}
			}
		},
		getTree() {
			let _this = this;
			this.$get('/user-api/ent/menu/tree').then(res => {
				this.permData = res.data;
				setTimeout(() => {
					_this.changeCss();
				}, 100);
				this.ListType = false;
				this.getDefaultShowNodes(2, this.permData);
				if (this.look == 0) {
					this.disabledFn(this.permData);
				}
			});
		},
		//关闭弹窗
		close() {
			this.form = {
				id: '',
				name: '',
				desc: ''
			};
			this.checkdKey = [];
			this.ListType = true;
			this.list();
		},
		remove(row) {
			this.$confirm('是否确认删除？', '删除角色', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/user-api/ent/role/delete', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		//提交表单
		submit() {
			if(this.form.name==''){
				this.$message.error('角色名称不能为空');
				return
			}
			this.$confirm('此操作将更改当前角色权限, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					var arr = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
					if (this.form.id) {
						this.$postData('/user-api/ent/role/add', {
							id: this.form.id,
							name: this.form.name,
							menuIds: arr
						}).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.close();
						});
					} else {
						this.$postData('/user-api/ent/role/add', {
							name: this.form.name,
							menuIds: arr
						}).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.close();
						});
					}
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
		},
		handleExpand() {
			//节点被展开时触发的事件
			//因为该函数执行在renderContent函数之前，所以得加定时
			setTimeout(() => {
				this.changeCss();
			}, 10);
		},
		renderContent(h, { node, data, store }) {
			//树节点的内容区的渲染 Function
			let classname = '';
			// 由于项目中有三级菜单也有四级级菜单，就要在此做出判断
			if (node.level === 4) {
				classname = 'foo';
			}

			// if (node.level === 3 && node.childNodes.length === 0) {
			// 	classname = 'foo';
			// }
			return h(
				'p',
				{
					class: classname
				},
				node.label
			);
		},
		changeCss() {
			var levelName = document.getElementsByClassName('foo'); // levelname是上面的最底层节点的名字
			for (var i = 0; i < levelName.length; i++) {
				// cssFloat 兼容 ie6-8  styleFloat 兼容ie9及标准浏览器
				levelName[i].parentNode.style.cssFloat = 'left'; // 最底层的节点，包括多选框和名字都让他左浮动
				levelName[i].parentNode.style.styleFloat = 'left';
				levelName[i].parentNode.onmouseover = function() {
					this.style.backgroundColor = '#F5F7FA';
				};
			}
		},
		checkFn(e, a) {
			// console.log(e);
			// console.log(a);
		},

		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
.fangda {
	font-size: 25px;
	cursor: pointer;
}
.treeBox {
	border: 1px solid #cccccc;
	flex: 1;
	// max-height: 1000px;
}
.spanssss {
	font-size: 14px;
	color: #606266;
	line-height: 40px;
	padding: 0 12px 0 0;
}

.el-tree {
	margin: 12px 0;
	display: flex;
	font-size: 16px;
}
::v-deep .el-tree-node {
	width: 300px;
}
::v-deep .el-collapse-item__header {
	height: 40px;
	background-color: #f4f4f4;
	padding: 0 10px;
}
::v-deep .el-icon-arrow-right:before {
	color: #409eff;
}

::v-deep .el-tree-node__content::before {
	content: '';
	padding-left: 10px;
}
::v-deep .el-checkbox__input {
	margin-right: 6px;
}
::v-deep .el-form-item {
	margin-bottom: 5px !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
	background-color: #409eff;
	border-color: #409eff;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
    border-color: #ffffff;
	}
.footer {
	padding-top: 10px;
	width: 100%;
	text-align: right;
}

// ::v-deep .el-tree {
//   height: 98%;
//   overflow: auto;
// }

// ::v-deep .el-tree>.el-tree-node {
//   display: inline-block;
//   min-width: 100%;
// }
</style>
